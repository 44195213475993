import Header from "./components/header";
import Footer from "./components/footer";
import FloatingMenu from "./common/speedDial/speedDial";
import { Carousel, Row, Col, BackTop, Modal, Spin } from "antd";
import {
  ArrowUpOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";

import "./App.css";
import boxGiftLeft from "../src/public/img/common/gift-box-left.png";
import boxGiftRight from "../src/public/img/common/gift-box-right.png";
import bgGiftLeft from "../src/public/img/common/centerd-page-left.png";
import bgGiftRight from "../src/public/img/common/centerd-page-right.png";
import bgGiftLeftMB from "../src/public/img/common/centerd-page-left-mb.png";
import bgGiftRightMB from "../src/public/img/common/centerd-page-right-mb.png";
import bannerBottom from "../src/public/img/common/banner-bottom.png";
import bannerBottomMB from "../src/public/img/common/banner-bottom-mb.png";
// import { Carousel } from "primereact/carousel";
import React, { useState, useEffect } from "react";
import experIcon1 from "../src/public/img/common/exper1.png";
import experIcon2 from "../src/public/img/common/exper2.png";
import experIcon3 from "../src/public/img/common/exper3.png";
import Toplist1 from "../src/public/img/common/top-list-1.png";
import Toplist2 from "../src/public/img/common/top-list-2.png";
import Toplist3 from "../src/public/img/common/top-list-3.png";
import Toplist4 from "../src/public/img/common/top-list-4.png";
import Bottomlist1 from "../src/public/img/common/bottom-list-1.png";
import Bottomlist2 from "../src/public/img/common/bottom-list-2.png";
import Bottomlist3 from "../src/public/img/common/bottom-list-3.png";
import BreakLine from "../src/public/img/common/break-line.png";
import Scale from "../src/public/img/common/scale.png";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

import "swiper/swiper.scss";
// import "swiper/modules/grid/grid.scss";
import "swiper/modules/navigation/navigation.scss";
import "swiper/modules/pagination/pagination.scss";
import { Player, Controls } from "@lottiefiles/react-lottie-player";

import SwiperCore, { Scrollbar, Grid, Navigation } from "swiper";
import smoQR from "../src/public/img/common/smo-qr.png";

SwiperCore.use([Grid, Scrollbar, Navigation]);

function App() {
  const settingResponsive = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    dotPosition: "top",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const settingResponsiveGuide = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dotPosition: "top",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  const settingResponsiveGroupCard = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    initialSlide: 0,
    dotPosition: "top",
    swipeToSlide: true,
    arrows: true,
    draggable: true,
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const settingResponsiveECard = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    dotPosition: "top",
    swipeToSlide: true,
    arrows: true,
    draggable: true,
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
    rows: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          arrows: false,
          rows: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          rows: 1,
        },
      },
    ],
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [ecardList, setEcardList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalQRVisible, setIsModalQRVisible] = useState(false);
  const [cardImg, setCardImg] = useState({});
  const [isSpining, setIsSpining] = useState(true);

  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-REQUEST-GROUP": "GIFY",
        "X-REQUEST-ID": "Chrome",
      },
      body: JSON.stringify({ lang: "VI", channel: "I" }),
    };

    fetch(
      "https://apigify.vps.com.vn/lanpage/landing-group-card",
      requestOptions
    )
      .then((results) => results.json())
      .then((data) => {
        console.log("data: ", data);
        let objCate = [
          {
            groupCardId: null,
            groupCardName: "Nổi bật",
            iconUrl: "123",
          },
        ];
        const objResult = data.data;
        objResult.forEach((item) => {
          objCate.push(item);
        });
        setCategoryList(objCate);
        handleToggle(0, null);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleToggle = (idx, id) => {
    setIsSpining(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-REQUEST-GROUP": "GIFY",
        "X-REQUEST-ID": "Chrome",
      },
      body: JSON.stringify({ lang: "VI", channel: "I", groupCardId: id }),
    };

    fetch(
      "https://apigify.vps.com.vn/lanpage/landing-list-card",
      requestOptions
    )
      .then((results) => results.json())
      .then((data) => {
        setEcardList([]);
        console.log("data: ", data);
        let objCard = [];
        const objResult = data.data;
        objResult.forEach((item) => {
          objCard.push(item);
        });
        setEcardList(objCard);
        setActiveIndex(idx);
        setIsSpining(false);
      })
      .catch((err) => console.log(err));
  };

  const handleToggleEcard = (idx, ecard) => {
    setIsModalVisible(true);
    setCardImg(ecard);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCardImg({});
  };

  const handleQRCode = (isVisible) => {
     Modal.info({
       title: "",
       className: "modal-qr",
       width: 350,
       closable: true,
       content: (
         <div className="popup-qr-parent">
           <div className="popup-qr">
             <img src={smoQR} />
           </div>
           <div className="popup-qr">
             <p>
               Quét mã QR <br /> để tải ứng dụng <br />{" "}
               <strong>VPS SmartOne</strong>
             </p>
           </div>
         </div>
       ),
     });
  };

  // const handleQRCode = () => {
  //   Modal.info({
  //     title: "This is a notification message",
  //     content: (
  //       <div>
  //         <p>some messages...some messages...</p>
  //         <p>some messages...some messages...</p>
  //       </div>
  //     )
  //   });
  // }

  return (
    <div className="App">
      <Header />
      <div className="TopPage">
        <Row align="middle">
          <Col xs={24} sm={24} md={12} className="TopPage__Left">
            <img src={boxGiftLeft} />
            <Row align="middle">
              <Col
                xs={{ span: 24, offset: 0 }}
                md={{ span: 24 }}
                lg={{ span: 18, offset: 6 }}
                xl={{ span: 16, offset: 8 }}
              >
                <div className="TopPage__boxLeft">
                  <h1>Tặng quà</h1>
                  <p className="hidden-sm">Trao quà tặng, gửi tri ân</p>
                  <p className="hidden-md">
                    Trao quà tặng,
                    <br /> gửi tri ân
                  </p>
                  <a
                    href="javascript:void()"
                    onClick={() => handleQRCode(true)}
                    className="animated-button thar-three btn-animate-custom hidden-sm"
                  >
                    Tặng quà bây giờ
                  </a>
                  <a
                    href="http://bit.ly/319dmz8"
                    className="animated-button thar-three btn-animate-custom hidden-md"
                  >
                    Tặng quà bây giờ
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} className="TopPage__Right">
            <img src={boxGiftRight} />
            <Row align="middle">
              <Col xs={24} md={24} lg={18} xl={16}>
                <div className="TopPage__boxRight">
                  <h1>Nhận quà</h1>
                  <p className="hidden-sm">Nhận quà tặng, mở yêu thương</p>
                  <p className="hidden-md">
                    Nhận quà tặng,
                    <br /> mở yêu thương
                  </p>
                  <a
                    href="javascript:void()"
                    onClick={() => handleQRCode(true)}
                    className="animated-button thar-four btn-animate-custom hidden-sm"
                  >
                    Nhận quà ngay
                  </a>
                  <a
                    href="http://bit.ly/319dmz8"
                    className="animated-button thar-four btn-animate-custom hidden-md"
                  >
                    Nhận quà ngay
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="PageDescription" id="PageDescription">
        <div className="container">
          <h1 className="color-orange">Gify</h1>
          <h1 className="color-dark">
            Trải nghiệm tặng & nhận quà <br /> chưa từng có
          </h1>
          <p>
            Gify đem đến cho khách hàng trải nghiệm tặng & nhận quà chưa từng có
            thông qua 2 sản phẩm{" "}
            <strong className="font-bold">Stockcard</strong> và{" "}
            <strong className="font-bold">Tiền mừng</strong>
            . <br />
            Một món quà độc đáo, giá trị và ý nghĩa chắc chắn sẽ là một bất ngờ
            đáng quý đối với người nhận. <br />
            Gify là sản phẩm liên kết của chúng tôi cùng Công ty Cổ phần Chứng
            khoán VPS, cung cấp tới khách hàng tiện ích được tích hợp trên ứng
            dụng <strong className="font-bold">VPS SmartOne</strong>.
          </p>

          {/* <Carousel
            value={products}
            numVisible={3}
            numScroll={3}
            responsiveOptions={responsiveOptions}
            itemTemplate={productTemplate}
            swipeToSlide={true}
          /> */}

          <Carousel {...settingResponsive} className="exp-block">
            <div className="product-item">
              <div className="product-item__content">
                <div className="product-item-content">
                  <div className="p-mb-3 imgPath">
                    <img src={experIcon1} />
                  </div>
                  <div>
                    <h4 className="p-mb-1 product-item__title m-b-0">
                      Quà tặng Stockcard,
                    </h4>
                    <h4 className="p-mb-1 product-item__title">
                      không thiếu lựa chọn,
                    </h4>
                    <p className="product-item__desc">
                      Đa dạng cổ phiếu, số lượng tùy chọn để dành tặng những
                      người yêu thương với 3 bước đơn giản tại{" "}
                      <b>VPS SmartOne</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-item">
              <div className="product-item__content">
                <div className="product-item-content">
                  <div className="p-mb-3 imgPath">
                    <img src={experIcon2} />
                  </div>
                  <div>
                    <h4 className="p-mb-1 product-item__title m-b-0">
                      Quà tặng tiền mừng,
                    </h4>
                    <h4 className="p-mb-1 product-item__title">
                      yêu thương không khó
                    </h4>
                    <p className="product-item__desc">
                      Dành tặng người thân và bạn bè thật nhiều “phong bao lì
                      xì” đặc biệt cùng Gify thay cho những lời chúc tốt đẹp
                      nhất.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-item">
              <div className="product-item__content">
                <div className="product-item-content">
                  <div className="p-mb-3 imgPath">
                    <img src={experIcon3} />
                  </div>
                  <div>
                    <h4 className="p-mb-1 product-item__title m-b-0">
                      Nhận quà dễ dàng,
                    </h4>
                    <h4 className="p-mb-1 product-item__title">
                      trọn ngàn niềm vui
                    </h4>
                    <p className="product-item__desc">
                      Tự tay khám phá bất ngờ đằng sau những món quà độc đáo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <div className="CenteredPage--Top" id="GiveGuide">
        <div className="CenteredPage--Top__Left-container">
          <Row className="hidden-sm" type="flex">
            <Col md={12} className="CenteredPage--Top__Left">
              <a
                href="javascript:void()"
                onClick={() => handleQRCode(true)}
                className="animated-button thar-pink btn-animate-custom"
              >
                Tặng quà ngay
              </a>
            </Col>
            <Col md={12} className="CenteredPage--Top__Right">
              <Row>
                <Col xs={24} md={17}>
                  <h1 className="color-dark text-left">
                    Tặng quà <span className="color-orange">Gify</span> đơn giản
                  </h1>
                  <div className="List-Guide--Top m-b-2rem">
                    <div className="List-Guide--Top__Left">
                      <img src={Toplist1} />
                    </div>
                    <div className="List-Guide--Top__Right text-left">
                      <h4>Tham gia nền tảng đa tiện ích</h4>
                      <p>
                        Mở tài khoản chứng khoán tại VPS <b>miễn phí</b> và dễ
                        dàng cùng các nền tảng trực tuyến của VPS.
                      </p>
                      <ul>
                        <li className="break-word">
                          <span className="color-orange">
                            <b>Nếu khách hàng đã có Tài khoản chứng khoán</b>
                          </span>{" "}
                          vui lòng chuyển sang bước 2.
                        </li>
                        <li className="break-word">
                          <span className="color-orange">
                            <b>Nếu khách hàng chưa có Tài khoản chứng khoán</b>
                          </span>{" "}
                          vui lòng Mở tài khoản chứng khoán để tặng quà.
                        </li>
                      </ul>
                      <p>Nạp tiền vào tài khoản chứng khoán.</p>
                    </div>
                  </div>
                  <div className="List-Guide--Top m-b-2rem">
                    <div className="List-Guide--Top__Left">
                      <img src={Toplist2} />
                    </div>
                    <div className="List-Guide--Top__Right text-left">
                      <h4>Tự tay lựa quà</h4>
                      <p>
                        Truy cập website 
                        <b>
                          <a href="https://gify.vn" target="_blank">
                            Gify.vn
                          </a>
                        </b>
                         chọn chức năng <strong>Tặng quà</strong> hoặc tìm kiếm
                        tiện ích <b>Gify</b> trên màn hình chính ứng dụng VPS
                        SmartOne. <br />
                        Bạn hãy chọn món quà muốn tặng: <b>Tiền mừng</b> hoặc 
                        <b>Stockcard</b>, cùng với những tấm thiệp xinh xắn.
                      </p>
                    </div>
                  </div>
                  <div className="List-Guide--Top m-b-2rem">
                    <div className="List-Guide--Top__Left">
                      <img src={Toplist3} />
                    </div>
                    <div className="List-Guide--Top__Right text-left">
                      <h4>Trao quà tặng</h4>
                      <p>
                        Chỉ với những thao tác đơn giản, <b>Gify</b> sẽ giúp bạn
                        trao tặng món quà độc đáo đem đến niềm vui bất ngờ tới
                        người nhận có hoặc không có tài khoản chứng khoán.
                      </p>
                    </div>
                  </div>
                  <div className="List-Guide--Top">
                    <div className="List-Guide--Top__Left">
                      <img src={Toplist4} />
                    </div>
                    <div className="List-Guide--Top__Right text-left">
                      <h4>Gửi tri ân</h4>
                      <p>
                        Xác thực để hoàn tất tặng quà. Trạng thái quà sẽ được
                        cập nhật thường xuyên tại tiện ích Gify trên ứng dụng
                        VPS SmartOne.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="hidden-md">
            <img className="hidden-md__bg" src={bgGiftLeftMB} />
            <div className="title--sm">
              <h4>Tặng quà Gify</h4>
              <h4>đơn giản</h4>
            </div>
            <Carousel {...settingResponsiveGuide}>
              <div className="List-Guide--Top">
                <div className="List-Guide--Top__Left">
                  <img src={Toplist1} />
                </div>
                <div className="List-Guide--Top__Right text-left">
                  <h4>Tham gia nền tảng đa tiện ích</h4>
                  <p>
                    Mở tài khoản chứng khoán tại VPS <b>miễn phí</b> và dễ dàng
                    cùng các nền tảng trực tuyến của VPS.
                  </p>
                  <ul>
                    <li className="break-word">
                      <span className="color-orange">
                        <b>Nếu khách hàng đã có Tài khoản chứng khoán</b>
                      </span>{" "}
                      vui lòng chuyển sang bước 2.
                    </li>
                    <li className="break-word">
                      <span className="color-orange">
                        <b>Nếu khách hàng chưa có Tài khoản chứng khoán</b>
                      </span>{" "}
                      vui lòng Mở tài khoản chứng khoán để tặng quà.
                    </li>
                  </ul>
                  <p>Nạp tiền vào tài khoản chứng khoán.</p>
                </div>
              </div>
              <div className="List-Guide--Top">
                <div className="List-Guide--Top__Left">
                  <img src={Toplist2} />
                </div>
                <div className="List-Guide--Top__Right text-left">
                  <h4>Tự tay lựa quà</h4>
                  <p>
                    Truy cập website 
                    <b>
                      <a href="https://gify.vn" target="_blank">
                        Gify.vn
                      </a>
                    </b>
                     chọn chức năng <strong>Tặng quà</strong> hoặc tìm kiếm tiện
                    ích <b>Gify</b> trên màn hình chính ứng dụng VPS SmartOne.{" "}
                    <br />
                    Bạn hãy chọn món quà muốn tặng: <b>Tiền mừng</b> hoặc 
                    <b>Stockcard</b>, cùng với những tấm thiệp xinh xắn.
                  </p>
                </div>
              </div>
              <div className="List-Guide--Top">
                <div className="List-Guide--Top__Left">
                  <img src={Toplist3} />
                </div>
                <div className="List-Guide--Top__Right text-left">
                  <h4>Trao quà tặng</h4>
                  <p>
                    Chỉ với những thao tác đơn giản, Gify sẽ giúp bạn trao tặng
                    món quà độc đáo đem đến niềm vui bất ngờ tới người nhận có
                    hoặc không có tài khoản chứng khoán.
                  </p>
                </div>
              </div>
              <div className="List-Guide--Top">
                <div className="List-Guide--Top__Left">
                  <img src={Toplist4} />
                </div>
                <div className="List-Guide--Top__Right text-left">
                  <h4>Gửi tri ân</h4>
                  <p>
                    Xác thực để hoàn tất tặng quà. Trạng thái quà sẽ được cập
                    nhật thường xuyên tại tiện ích Gify trên ứng dụng VPS
                    SmartOne.
                  </p>
                </div>
              </div>
            </Carousel>
            <div className="btn-receive-mobile">
              <a
                href="http://bit.ly/319dmz8"
                target="_blank"
                className="btn-send-mobile__a"
              >
                Tặng quà bây giờ
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="CenteredPage--Bottom" id="ReceiveGuide">
        <div className="CenteredPage--Bottom__Left-container">
          <Row className="hidden-sm" type="flex">
            <Col md={12} className="CenteredPage--Bottom__Left">
              <Row>
                <Col md={{ span: 17, offset: 7 }}>
                  <h1 className="color-dark text-left">
                    Nhận quà <span className="color-orange">Gify</span> dễ dàng
                  </h1>
                  <div className="List-Guide--Bottom m-b-4rem">
                    <div className="List-Guide--Bottom__Left">
                      <img src={Bottomlist1} />
                    </div>
                    <div className="List-Guide--Bottom__Right text-left">
                      <h4>Nhận quà tại VPS SmartOne</h4>
                      <p>
                        Mở tài khoản chứng khoán tại VPS để tiến hành nhận quà
                        qua ứng dụng SmartOne. <br />
                        Tìm kiếm tính năng <b>Gify</b> và nhập mã đã được thông
                        báo (email/SMS) để xem món quà được nhận.
                      </p>
                    </div>
                  </div>
                  <div className="List-Guide--Bottom m-b-4rem">
                    <div className="List-Guide--Bottom__Left">
                      <img src={Bottomlist2} />
                    </div>
                    <div className="List-Guide--Bottom__Right text-left">
                      <h4>Mở yêu thương</h4>
                      <p>
                        Tại tiện ích Gify - <b>Quà của tôi</b> trên ứng dụng
                        SmartOne, lựa chọn <strong>Nhận quà</strong> và làm theo
                        hướng dẫn để khám phá món quà đặc biệt này.
                      </p>
                    </div>
                  </div>
                  <div className="List-Guide--Bottom m-b-4rem">
                    <div className="List-Guide--Bottom__Left">
                      <img src={Bottomlist3} />
                    </div>
                    <div className="List-Guide--Bottom__Right text-left">
                      <h4>Khám phá điều bất ngờ</h4>
                      <p>
                        Nhận ngay giá trị <b>Tiền mừng</b> vào tài khoản hoặc sở
                        hữu các mã cổ phiếu giá trị với <b>Stockcard</b>. <br />
                        Trạng thái quà sẽ được cập nhật thường xuyên tại tiện
                        ích Gify trên ứng dụng VPS SmartOne.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12} className="CenteredPage--Bottom__Right p-l-3rem">
              <a
                href="javascript:void()"
                onClick={() => handleQRCode(true)}
                className="animated-button thar-blue btn-animate-custom"
              >
                Nhận quà ngay
              </a>
              {/* <img src={bgGiftRight} /> */}
            </Col>
          </Row>

          <div className="hidden-md">
            <img className="hidden-md__bg" src={bgGiftRightMB} />
            <div className="title--sm">
              <h4>Nhận quà Gify</h4>
              <h4>dễ dàng</h4>
            </div>
            <Carousel {...settingResponsiveGuide}>
              <div className="List-Guide--Bottom">
                <div className="List-Guide--Bottom__Left">
                  <img src={Bottomlist1} />
                </div>
                <div className="List-Guide--Bottom__Right text-left">
                  <h4>Nhận quà tại VPS SmartOne</h4>
                  <p>
                    Mở tài khoản chứng khoán tại VPS để tiến hành nhận quà qua
                    ứng dụng SmartOne. <br />
                    Tìm kiếm tính năng <b>Gify</b> và nhập mã đã được thông báo
                    (email/SMS) để xem món quà được nhận.
                  </p>
                </div>
              </div>
              <div className="List-Guide--Bottom">
                <div className="List-Guide--Bottom__Left">
                  <img src={Bottomlist2} />
                </div>
                <div className="List-Guide--Bottom__Right text-left">
                  <h4>Mở yêu thương</h4>
                  <p>
                    Tại tiện ích Gify - <b>Quà của tôi</b> trên ứng dụng
                    SmartOne, lựa chọn <strong>Nhận quà</strong> và làm theo
                    hướng dẫn để khám phá món quà đặc biệt này.
                  </p>
                </div>
              </div>
              <div className="List-Guide--Bottom">
                <div className="List-Guide--Bottom__Left">
                  <img src={Bottomlist3} />
                </div>
                <div className="List-Guide--Bottom__Right text-left">
                  <h4>Khám phá điều bất ngờ</h4>
                  <p>
                    Nhận ngay giá trị <b>Tiền mừng</b> vào tài khoản hoặc sở hữu
                    các mã cổ phiếu giá trị với <b>Stockcard</b>. <br />
                    Trạng thái quà sẽ được cập nhật thường xuyên tại tiện ích
                    Gify trên ứng dụng VPS SmartOne.
                  </p>
                </div>
              </div>
            </Carousel>
            <div className="btn-receive-mobile">
              <a
                href="http://bit.ly/319dmz8"
                target="_blank"
                className="btn-receive-mobile__a"
              >
                Nhận quà ngay
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="EcardBlock" id="EcardBlock">
        <img src={BreakLine} className="breakLine m-b-2rem"></img>
        <div className="container">
          <h1 className="color-orange">Thiệp quà tặng gợi ý</h1>
          <p>
            Một món quà, vạn yêu thương, ngàn giá trị. Gify sẽ thay bạn gửi đi
            những món quà ý nghĩa, <br /> thay cho những lời chúc tốt đẹp nhất.
          </p>
          <Carousel {...settingResponsiveGroupCard} className="category--slick">
            {categoryList.map((post, idx) => (
              <label
                className={
                  activeIndex == idx
                    ? "active title-group"
                    : "inactive title-group"
                }
                onClick={() => handleToggle(idx, post.groupCardId)}
              >
                {post.groupCardName}
              </label>
            ))}
          </Carousel>
          <Spin spinning={isSpining} />
          {/* <Carousel
            {...settingResponsiveECard}
            className="category--slick category--slick__ecard"
          >
            {ecardList.map((ecard, idx) => (
              <div
                onClick={() => handleToggleEcard(idx, ecard)}
                className="p-2rem"
              >
                <a className="p-2rem ecard-body">
                  <img src={ecard.cardUrl} />
                  <span className="card--title">{ecard.cardName}</span>
                </a>
              </div>
            ))}
          </Carousel> */}
          <div className="swiperParent">
            <Swiper
              navigation={true}
              scrollbar={{
                hide: true,
                updateOnWindowResize: true,
                longSwipes: true,
              }}
              className="mySwiper"
              // slidesPerView={3}
              // grid={{
              //   rows: 2,
              // }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  grid: { rows: 1 },
                  adaptiveHeight: true,
                },
                480: {
                  slidesPerView: 1,
                  grid: { rows: 1 },
                  adaptiveHeight: true,
                },
                768: {
                  slidesPerView: 3,
                  grid: { rows: 2, fill: "row" },
                  adaptiveHeight: true,
                },
              }}
            >
              {ecardList.map((ecard, idx) => (
                <SwiperSlide>
                  <div
                    onClick={() => handleToggleEcard(idx, ecard)}
                    className="p-2rem"
                  >
                    <a className="p-2rem ecard-body">
                      <img src={ecard.cardUrl} />
                      <div>
                        <Row>
                          <Col
                            xs={18}
                            className="card--title card--title__left"
                          >
                            {ecard.cardName}
                          </Col>
                          <Col
                            xs={6}
                            className="card--title card--title__right"
                          >
                            <img src={Scale}></img>
                          </Col>
                        </Row>
                      </div>
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <Modal
            title={cardImg.cardName}
            visible={isModalVisible}
            centered
            onCancel={handleCancel}
            className="modal--showImg"
          >
            {/* <img src={cardImg.cardUrl} /> */}
            <Player
              autoplay
              loop
              src={cardImg.cardAnimationUrl}
              // style={{ height: "70vh" }}
              className="animation-player"
            ></Player>
          </Modal>
        </div>
      </div>
      <div className="BannerBlock">
        <img className="w-full hidden-sm" src={bannerBottom} />
        <img className="w-full hidden-md" src={bannerBottomMB} />
      </div>
      <Footer />
      <BackTop>
        <button className="back-to-top">
          <ArrowUpOutlined />
        </button>
      </BackTop>
      <FloatingMenu />
      <Modal
        title="Vertically centered modal dialog"
        centered
        visible={isModalQRVisible}
        onOk={() => handleQRCode(false)}
      >
        <p>some contents...</p>
        <p>some contents...</p>
        <p>some contents...</p>
      </Modal>
    </div>
  );
}

export default App;
