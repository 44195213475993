import React, { Component } from "react";
import { Button, DatePicker, Row, Col } from "antd";
import smoLogo from "../../public/img/common/smo-logo.png";
import gifyLogo from "../../public/img/common/gify-logo.png";
import smoQR from "../../public/img/common/smo-qr.png";
import android from "../../public/img/common/android.png";
import ios from "../../public/img/common/ios.png";
import guildePC from "../../public/qa/HDSD_Gify_Desktop.pdf";
import guildeMb from "../../public/qa/HDSD_Gify_Mobile.pdf";
import qAFile from "../../public/qa/Q&A_Gify.pdf";

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="footer--top">
          <div className="container">
            <div className="footer--top__container">
              <Row>
                <Col className="text-left hidden-sm" xs={24} md={5}>
                  <div>
                    <div>
                      <img src={smoLogo} className="m-b-5rem m-w-90pc" />
                    </div>
                    <div>
                      <img src={gifyLogo} className="m-w-90pc" />
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="text-left hidden-md">
                  <img src={gifyLogo} className="m-b-sm-3rem" />
                </Col>
                <Col xs={12} className="text-right hidden-md">
                  <img src={smoLogo} className="p-t-sm-1rem" />
                </Col>
                <Col className="text-left" xs={12} md={4}>
                  <a href="#PageDescription">Về Gify</a>
                  <a href={guildePC} target="_blank" className="hidden-sm">
                    Hướng dẫn sử dụng
                  </a>
                  <a href={guildeMb} target="_blank" className="hidden-md">
                    Hướng dẫn sử dụng
                  </a>
                  <a href={qAFile} target="_blank">
                    FAQ
                  </a>
                  <a href="tel:19006457" className="hidden-md">
                    Hotline: 1900 6457
                  </a>
                </Col>
                <Col className="text-left hidden-sm" md={4}>
                  <a href="tel:19006457">Hotline: 1900 6457</a>
                </Col>
                <Col md={3} className="hidden-sm"></Col>
                <Col className="text-left hidden-sm" md={4}>
                  <img src={smoQR} className="m-w-90pc" />
                </Col>
                <Col className="text-left text-right-sm" xs={12} md={4}>
                  <p>Tải ngay VPS SmartOne</p>
                  <a
                    href="https://play.google.com/store/apps/details?id=vn.com.vpbs.smartone&hl=vi&gl=US"
                    className="hidden-sm"
                    target="_blank"
                  >
                    <img src={android} className="m-b-8rem m-w-90pc" />
                  </a>
                  <a
                    href="https://apps.apple.com/vn/app/vps-smartone/id1431656423?l=vi"
                    className="hidden-sm"
                    target="_blank"
                  >
                    <img src={ios} className="m-w-90pc" />
                  </a>
                  <a
                    href="http://bit.ly/319dmz8"
                    className="hidden-md"
                    target="_blank"
                  >
                    <img src={android} className="m-b-8rem m-w-90pc" />
                  </a>
                  <a
                    href="http://bit.ly/319dmz8"
                    className="hidden-md"
                    target="_blank"
                  >
                    <img src={ios} className="m-w-90pc" />
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="footer--bottom">
          <div className="container">
            <p>© 2021 Gify All rights reserved</p>
          </div>
        </div>
      </footer>
    );
  }
}
