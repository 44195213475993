import React, { Component, useState, useEffect, useRef } from "react";
import { Row, Col } from "antd";
import logo from "../../public/img/logo.png";
import axios from "axios";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import menuOpen from "../../public/img/common/menu-open.png";
import menuClose from "../../public/img/common/menu-close.png";

export default class Header extends React.Component {
  // const [scroll, setScroll] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     setScroll(window.scrollY > 50);
  //   });
  // }, []);

  constructor(props) {
    super(props);

    this.state = {
      scroll: false,
      any: "",
      click: false,
    };
    // This binding is necessary to make `this` work in the callback
    this.handleClick123 = this.someFunction.bind(this);
    this.closeMobileMenu = this.closeMobileMenuHandle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      this.setState({ scroll: window.scrollY > 50 });
    });

    console.log("this.state: ", this.state);
  }

  someFunction(value) {
    console.log("value: ", value);
    this.someFuntion2("string 2");
  }

  someFuntion2(value) {
    console.log("value2: ", value);
  }

  closeMobileMenuHandle() {
    this.setState({ click: false });
  }

  handleClick() {
    this.setState({ click: !this.state.click });
  }

  render() {
    return (
      <header
        className={
          this.state.scroll ? "bg-black App-header" : "bg-white App-header"
        }
      >
        <Row align="middle">
          <Col span={12}>
            <a href="#">
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          </Col>
          <Col span={12}>
            {/* <ul className="ul-menu-pc">
              <li>
                <a
                  onClick={() => {
                    this.handleClick123("string123");
                  }}
                >
                  Hướng dẫn Tặng quà
                </a>
                <a href="#">Hướng dẫn Nhận quà</a>
                <a href="#">Thiệp Gify</a>
              </li>
            </ul> */}
            <ul
              className={
                this.state.click ? "nav-options active" : "nav-options"
              }
            >
              <li
                className="option"
                onClick={() => {
                  this.closeMobileMenu();
                }}
              >
                <a href="#GiveGuide">Hướng dẫn Tặng quà</a>
                <a href="#ReceiveGuide">Hướng dẫn Nhận quà</a>
                <a href="#EcardBlock">Thiệp Gify</a>
              </li>
            </ul>
            <div
              className="mobile-menu"
              onClick={() => {
                this.handleClick();
              }}
            >
              {!this.state.click ? (
                <img src={menuOpen}></img>
              ) : (
                <img src={menuClose}></img>
              )}
            </div>
          </Col>
        </Row>
      </header>
    );
  }
}
