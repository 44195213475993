import React, { Component, useRef, useState } from "react";

import { SpeedDial } from "primereact/speeddial";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "./SpeedDialDemo.css";
import SMOFloating from "../../public/img/common/floating-smo.png";
import GuideFloating from "../../public/img/common/floating-guide.png";
import FAQFloating from "../../public/img/common/floating-faq.png";
import { Modal } from "antd";
import smoQR from "../../public/img/common/smo-qr.png";
import qAFile from "../../public/qa/Q&A_Gify.pdf";
import guildePC from "../../public/qa/HDSD_Gify_Desktop.pdf";
import guildeMb from "../../public/qa/HDSD_Gify_Mobile.pdf";

export default function FloatingMenu() {

    const toast = useRef(null);
    const [showBorder, setShowBorder] = useState(false);

    const handleShowBorder = () => {
      setShowBorder(!showBorder);
    };

    const items = [
      {
        label: "Update",
        template: (item, options) => {
          return (
            <a href={qAFile} target="_blank">
              <img src={FAQFloating} alt="FAQ" />
            </a>
          );
        },
        command: () => {
          toast.current.show({
            severity: "success",
            summary: "Update",
            detail: "Data Updated",
          });
        },
      },
      {
        label: "Add",
        template: (item, options) => {
          return (
            <a href={guildeMb} target="_blank">
              <img src={GuideFloating} alt="FAQ" />
            </a>
          );
        },
        command: () => {
          toast.current.show({
            severity: "info",
            summary: "Add",
            detail: "Data Added",
          });
        },
      },
      {
        label: "Delete",
        template: (item, options) => {
          return (
            <a href="http://bit.ly/319dmz8" target="_blank">
              <img src={SMOFloating} alt="VPS SmartOne" />
            </a>
          );
        },
        command: () => {
          toast.current.show({
            severity: "error",
            summary: "Delete",
            detail: "Data Deleted",
          });
        },
      },
    ];

    const itemsPC = [
      {
        label: "Update",
        template: (item, options) => {
          return (
            <a href={qAFile} target="_blank">
              <img src={FAQFloating} alt="FAQ" />
            </a>
          );
        },
        command: () => {
          toast.current.show({
            severity: "success",
            summary: "Update",
            detail: "Data Updated",
          });
        },
      },
      {
        label: "Add",
        template: (item, options) => {
          return (
            <a href={guildePC} target="_blank">
              <img src={GuideFloating} alt="FAQ" />
            </a>
          );
        },
        command: () => {
          toast.current.show({
            severity: "info",
            summary: "Add",
            detail: "Data Added",
          });
        },
      },
      {
        label: "Delete",
        template: (item, options) => {
          return (
            <a href="javascript:void()" onClick={() => handleQRCode()}>
              <img src={SMOFloating} alt="VPS SmartOne" />
            </a>
          );
        },
        command: () => {
          toast.current.show({
            severity: "error",
            summary: "Delete",
            detail: "Data Deleted",
          });
        },
      },
    ];

    const handleQRCode = () => {
      Modal.info({
        title: "",
        className: "modal-qr",
        width: 350,
        closable: true,
        content: (
          <div className="popup-qr-parent">
            <div className="popup-qr">
              <img src={smoQR} />
            </div>
            <div className="popup-qr">
              <p>
                Quét mã QR <br /> để tải ứng dụng <br />{" "}
                <strong>VPS SmartOne</strong>
              </p>
            </div>
          </div>
        ),
      });
    };

    return (
      <div className={showBorder ? "border-solid-radius" : ""}>
        <div className={showBorder ? "border-dotLine-radius" : ""}>
          <SpeedDial
            model={itemsPC}
            radius={80}
            direction="left"
            className="speeddial-right hidden-sm"
            type="semi-circle"
            showIcon="pi pi-sign-out"
            hideIcon="pi pi-times"
            onShow={handleShowBorder}
            onHide={handleShowBorder}
          />
          <SpeedDial
            model={items}
            radius={80}
            direction="left"
            className="speeddial-right hidden-md"
            type="semi-circle"
            showIcon="pi pi-sign-out"
            hideIcon="pi pi-times"
            onShow={handleShowBorder}
            onHide={handleShowBorder}
          />
        </div>
      </div>
    );
}